.property-hero {
    &__container {
        @apply
            tw-h-[30rem]
            tw-w-full
            tw-overflow-hidden
            tw-relative
            xl:tw-h-[33rem]
        ;

        &--disney {
            @apply
                tw-h-[10rem]
                sm:tw-h-[15rem]
                md:tw-h-[20rem]
                lg:tw-h-[25rem]
                xl:tw-h-[30rem]
                2xl:tw-h-[33rem]
            ;
        }

        &--hotel {
            @apply
                tw-h-[20rem]
                sm:tw-h-[15rem]
                lg:tw-h-[25rem]
                xl:tw-h-[30rem]
                2xl:tw-h-[33rem]
            ;
        }
    }

    &__bg {
        @apply
            tw-absolute
            tw-blur-2xl
            tw-h-full
            tw-hidden
            2xl:tw-block
        ;

        &--right {
            @apply
                tw-right-0
            ;
        }
    }

    &__img {
        @apply
            tw-h-full
            tw-w-full
            tw-object-cover
            tw-object-bottom
            tw-relative
            sm:tw-object-right-bottom
            2xl:tw-block
            2xl:tw-object-[unset]
            2xl:tw-mx-auto
            2xl:tw-w-auto
        ;

        &--disney, &--hotel {
            @apply
                sm:tw-object-bottom
            ;
        }
    }
}
