@import "css/components/property-hero.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --swiper-theme-color: #fff;
    }

    .tw-box-cascade, .tw-box-cascade:before, .tw-box-cascade:after,
    .tw-box-cascade *, .tw-box-cascade *:before, .tw-box-cascade *:after {
        box-sizing: inherit;
    }
}

@layer components {
    .swiper-pagination-bullet {
        @apply tw-bg-white tw-w-4 tw-h-4 tw-opacity-20;
    }

    .swiper-pagination-bullet-active {
        @apply tw-bg-transparent tw-border-solid tw-border-white;
        border-width: 3px;
    }

    .sliding-promo-table-grid {
        @apply tw-grid tw-grid-flow-row;
        grid-template-columns: repeat(5, minmax(0, 1fr));
        grid-template-rows: auto repeat(8, minmax(0, 1fr));
        font-size: 0.5rem;
        line-height: 1;
    }

    @screen md {
        .sliding-promo-table-grid {
            @apply tw-text-xs;
            grid-template-columns: repeat(2, minmax(0, auto)) repeat(3, minmax(0, 1fr));
        }
    }
}

@layer utilities {
    [x-cloak] {
        @apply tw-hidden;
    }

    .tw-container-inner {
        @apply md:tw-w-sm lg:tw-w-md xl:tw-w-lg;
    }

    .tw-container-inner.wider {
        @apply md:tw-w-[728px] lg:tw-w-[1004px] xl:tw-w-lg;
    }

    .tw-column-count-auto {
        column-count: auto;
    }

    .tw-column-count-4 {
        column-count: 4;
    }

    .tw-column-gap-8 {
        column-gap: 2rem;
    }

    .tw-break-inside-avoid {
        break-inside: avoid;
    }

    .tw-shadow-t-md {
        box-shadow: 0 -4px 6px -3px rgba(0, 0, 0, 0.1), 0 -2px 4px -2px rgba(0, 0, 0, 0.06);
    }

    .tw-shadow-r-md {
        box-shadow: 4px 0 6px -3px rgba(0, 0, 0, 0.1), 2px 0 4px -2px rgba(0, 0, 0, 0.06);
    }

    .tw-shadow-b-md {
        box-shadow: 0 4px 6px -3px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.06);
    }

    .tw-loader {
        @apply tw-ease-linear tw-rounded-full tw-border-4 tw-border-t-4 tw-border-zinc-200 tw-border-solid tw-h-8 tw-w-8 tw-mb-4;

        border-top-color: #3498db !important;
        -webkit-animation: spinner 1.5s linear infinite;
        animation: spinner 1.5s linear infinite;
    }

    .tw-icons-vacatia-blue i {
        @apply tw-text-vacatia-blue;
    }

    @-webkit-keyframes spinner {
        0% {
            -webkit-transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
        }
    }

    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
